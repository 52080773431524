import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import "../styles/bg.css";
import {AdIosaregionSwipetoslide} from "../components/react-slick/ad-iosaregion-swipetoslide";
import title from "../images/sale/xr-page1-title.gif";
import {Link} from "gatsby";
import KaisakuApi from "../controllers/kaisaku-api";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            maxCount: 0,
            saleCount: 0,
            ids:'',
            message:''
        };
        let { userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL,ids, message} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        this.state.ids = ids;
        if (!message) {
            message = "沒有任何信息";
        }
        this.state.message = message;
    }

    async componentDidMount(){

    }



    render() {
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                        <div style={{
                            width: '289px',
                            borderRadius: '10px 10px 0px 0px',
                            display: ''
                        }}>

                            <div style={{width:'289px', borderRadius:'10px 10px 0px 0px',backgroundColor:"#262626",borderLeft:'1px solid #444',borderTop:'1px solid #444',borderRight:'1px solid #444',}}
                            >
                                <Image style={{borderRadius:'10px 10px 0px 0px'}} centered src={title}/>
                            </div>
                            <div style={{width:'289px',height:"56vh",maxHeight:"400px",overflowY:"auto",backgroundColor:"#262626",
                            borderLeft:'1px solid #444',borderRight:'1px solid #444',}}
                            >
                                <div style={{width:"100%",color:"#E4E4E4",fontSize:"18px",padding:"20px",fontWeight:'bold'}}>以下信息請截圖保存</div>
                                <div style={{width:"100%",color:"#E4E4E4",fontSize:"16px",padding:"20px"}} dangerouslySetInnerHTML={{ __html:decodeURI(this.state.message)}}></div>
                            </div>
                            <div style={{height:'41px',textAlign:'center',fontSize:'14px',fontWeight:'bold',
                                paddingTop:'14px',border:'1px solid #444',
                                borderRadius:'0px 0px 10px 10px',
                                opacity:'1'}}
                                 onClick={async () => {
                                     const data = { ids: this.state.ids};
                                     await KaisakuApi.setReadStatus(data, this.state.accessToken);
                                     // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                     AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                 }}
                            >
                                <span style={{color:'#FF7700'}}>知道了~</span>
                            </div>
                        </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});